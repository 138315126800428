import "./Cover.css"
import EditBlock from "./EditBlock"
import { useState, useEffect } from "react"
import Loading from "./Loading"
import TextInput from "./TextInput"

export default function Cover({page, editMode, imageLoaders, getPageImage, textEditRef, descEditRef, onGenerateNewImage, onRevertImage}) {
    const {text, desc} = page
    const [title, setTitle] = useState(text)
    const [imagePrompt, setImagePrompt] = useState(desc)
    const [image, setImage] = useState("")

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(imageLoaders[page.index])
    }, [imageLoaders])

    useEffect(() => {
        if (loading === false || loading === "reload") {
            setImage(getPageImage(page.index))
        }
    }, [loading])

    useEffect(() => {
        setTitle(text)
        setImagePrompt(desc)
    }, [page])


    return (
        <div className={`book-cover ${loading === true ? "is-loading" : ""}`} >
            <div className="book-image"  style={{backgroundImage: `url(${image})`}}></div>
            <div className="book-title">
                {!editMode && 
                    <span>{text}</span>
                }
                {editMode &&
                    <TextInput
                        type={"input"}
                        text={title}
                        placeholder={"Book title..."}
                        ref={textEditRef}
                    />
                }

                {loading === true && 
                    <Loading />
                }
                
            </div>
            {editMode &&
                <div className="image-input">
                    <EditBlock
                        text={imagePrompt}
                        placeholder={"Image prompt..."}
                        showButtons={true}
                        onSubmit={onGenerateNewImage}
                        editRef={descEditRef}
                        onUndo={onRevertImage}
                    />
                </div>
            }
        </div>
    )
}

