import "./EditBlock.css"
import TextInput from "./TextInput"

export default function EditBlock ({text, showButtons = false, editRef, onSubmit = () => {}, onUndo = () => {}}) {

    return (
        <div className="edit-block">
            <TextInput
                type={"textarea"}
                text={text}
                ref={editRef}
                placeholder={showButtons ? "Picture description..." : "Write something..."}
            />

            {showButtons &&
                <div className="edit-buttons">
                    <button className="button icon-btn"
                        onClick={onUndo}
                    >
                        <span className="icon print" style={{maskImage: "url(./img/icon-undo.svg)"}}></span> Undo
                    </button>
                    <button className="button primary-btn"
                        onClick={onSubmit}
                    >New Picture</button>
                </div>
            }
            
        </div> 
    )
}
