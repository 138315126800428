import { useState, useEffect, useRef } from "react"

export default function Image({index, getPageImage, loading = undefined}) {
    const [image, setImage] = useState(null)
    const prevLoad = useRef(true)

    let prevIndex = -2

    useEffect(() => {
        if (loading === false || loading === "reload") {
            setImage(getPageImage(index, true))
        }
    }, [loading])

    useEffect(() => {
        if (image === null && index !== prevIndex) {
            setImage(getPageImage(index))
        }

        prevIndex = index
    }, [])

    return (
        <div className="image" 
            style={{backgroundImage: `url(${image})`, maskImage: `url(./img/rect.svg)`}}>
        </div>
    )

}