import IntroScreen from "./IntroScreen"
import { useState, useEffect } from "react"
import Book from "./Book"
import PostIt from "./PostIt"
import { validateSummary, getStory, getImage } from "../Utils"
import PrintBook from "./PrintBook"

const endpointURL = "https://createastory.onrender.com"
//const endpointURL = "http://localhost:4000"

let tempLoader = []
let tempImages = []

export default function HomePage() {
    const [openBook, setOpenBook] = useState(false)
    const [shouldCloseBook, setShouldCloseBook] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [story, setStory] = useState([])
    const [images, setImages] = useState([])
    const [imageLoaders, setImageLoaders] = useState([])
    const [summary, setSummary] = useState("")
    const [loading, setLoading] = useState(false)
    const [showNextBtn, setShowNextBtn] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [isNewStory, setIsNewStory] = useState(false)
    const [printMode, setPrintMode] = useState(false)
    const [apiLive, setApiLive] = useState(false)

    useEffect(() => {
        tempImages = images
    }, [images])
    
    useEffect(() => {
        if (shouldCloseBook) {
            setShowNextBtn(true)
            setTimeout(() => {
                setOpenBook(false)
                setShouldCloseBook(false)
            }, 1500)
        }
    }, [shouldCloseBook])

    useEffect(() => {
        if (isNewStory && story) {
            generateImage(story[0].desc, 0)
        }
    }, [isNewStory])

    useEffect(() => {

        tempLoader = imageLoaders

        if (isNewStory && imageLoaders[0] === false ) {
            setOpenBook(true)
            setIsNewStory(false)
        }

        if (isPrinting && imageLoaders.indexOf(true) === -1) {

            if (imageLoaders.indexOf(null) === -1) {
                setIsPrinting(false)
                setLoading(false)
                sendToPrinter()
            } else {
                collectImages()
            }
        }

        

    }, [imageLoaders])

    useEffect(() => {
        getApiStatus()  
    },[])

    const onCreateBook = (newSummary) => {
        if (validateSummary(summary, newSummary)) {
            generateNewStory(newSummary)
            setSummary(newSummary)
            setShowNextBtn(false)
        }
    }

    const generateNewStory = async(prompt) => {
        
        setLoading(true)

        const result = await getStory(endpointURL, prompt)

        if (result.type === "success") {
            const newStory = result.payload

            setStory(newStory)
            setIsNewStory(true)
            setImages(Array(newStory.length).fill(null))
            setImageLoaders(Array(newStory.length).fill(null))
            tempLoader = Array(newStory.length).fill(null)
            tempLoader = Array(newStory.length).fill(null)
        }

    }

    const generateImage = async(prompt, index) => {
        
        if (isNaN(index) || index < 0) {
            return
        }

        setLoader(index, true)

        const result = await getImage(endpointURL, prompt)

        if (result.type === "success") {
            const newImages = [...tempImages]
            newImages[index] = result.payload.image
            setImages(newImages)
        }

        if (index === 0)
            setLoading(false)

        setLoader(index, false)
    }


    const setLoader = (index, val) => {
        const newLoaders = [...tempLoader]
        newLoaders[index] = val
        setImageLoaders(newLoaders)
    }

    const getPageImage = (index, cache = false) => {
        
        if (index == -1 || !story[index]) return 
        
        if (images[index] || cache) {
            const imgStr = "data:image/png;base64," + images[index]

            return imgStr
        }
        else {
            if (imageLoaders[index] === null)
                generateImage(story[index].desc, index)

            return "./img/image.svg"
        }     
    }

    const setPageImage = (imgText, index) => {
        const newImages = [...images]
        newImages[index] = imgText.split("data:image/png;base64,")[1]
        setImages(newImages)
        setLoader(index, "reload")
    }

    const getApiStatus = async() => {
        try {
            const response = await fetch(endpointURL);
            if (!response.ok) {
                setShowPopup(true)
            }  else {
                setApiLive(true)
            }
            
        } catch (error) {
            setShowPopup(true)
        }
    }

    const onReopenBook = () => {
        setShowNextBtn(false)
        setOpenBook(true)
    }

    const collectImages = () => {
        const index = imageLoaders.indexOf(null)

        if (index > -1) {
            generateImage(story[index].desc, index)
        } 
    }

    const sendToPrinter = () => {
        /*
        localStorage.setItem("printBookState", JSON.stringify({
            story: story,
            images: images
        }));
        window.open("/print", "_blank", "noreferrer");
        */
       setPrintMode(true)
    }


    const printBook = () => {
        if (isAllImagesLoaded()) {
            sendToPrinter()
        } else {
            collectImages()
            setIsPrinting(true)
            setLoading(true)
        }
    }

    const isAllImagesLoaded = () => imageLoaders.indexOf(null) === -1 && imageLoaders.indexOf(true) === -1

    return (
        <div className="wrapper">
            {printMode && 
                <PrintBook
                    images={images}
                    story={story}
                    setPrintMode={setPrintMode}
                />
            }
            <div className="app" style={{opacity: `${printMode ? "0" : "1"}`}}>
                <div className="app-bg">
                    <div className="bg-gfx"></div>
                </div>

                {showNextBtn &&
                    <button className="button large-icon icon-btn nav-btn right"
                        onClick={onReopenBook}
                    >
                        <span className="icon print" style={{maskImage: "url(./img/icon-arrow.svg)"}}></span>
                    </button>
                }

                {showPopup &&
                    <PostIt />
                }

                <IntroScreen 
                    loading={loading}
                    onCreateBook={onCreateBook}
                    apiLive={apiLive}
                />

                {openBook &&
                    <Book
                        openBook={openBook}
                        setOpenBook={setOpenBook}
                        setShouldCloseBook={setShouldCloseBook}
                        shouldCloseBook={shouldCloseBook}
                        story={story}
                        getPageImage={getPageImage}
                        loading={loading}
                        generateImage={generateImage}
                        setPageImage={setPageImage}
                        imageLoaders={imageLoaders}
                        printBook={printBook}
                    />
                }
            </div>
        </div>
    )
}