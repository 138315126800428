import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import HomePage from "./Components/HomePage"
import PrintBook from "./Components/PrintBook";

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/print" component={PrintBook} />
      </Switch>
    </Router>
  );
};

export default App;
