import { useState, forwardRef, useImperativeHandle, useEffect } from "react"


const TextInput = forwardRef(({ type, text, placeholder = "Write something..." }, ref) => {
    const [value, setValue] = useState(text)

    useEffect(() => {
        setValue(text)
    }, [text])
    
    useImperativeHandle(ref, () => ({
        getTextChanges () {
            return value
        }
    }))

    if (type === "input") {
        return (
            <input
                placeholder={placeholder}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        )
    }
    return (

        <textarea 
            placeholder={placeholder}
            value={value}
            onChange={(e) => {setValue(e.target.value)}}
        >
        </textarea>
    )
})

export default TextInput