
export const validateSummary = (bookSummary, newSummary) => bookSummary !== newSummary

export const getStory = async (url, prompt) => callEndpoint(url + "/getstory", prompt)

export const getImage = async (url, prompt) => callEndpoint(url + "/getimage", prompt)

const prepareStory = (payload) => {
    const {text, pictures} = payload
    const story = text.story
    const descriptions = pictures.descriptions
    
    let result = []

    result.push({
        text: story[0].title,
        desc: descriptions[0].cover
    })

    for (let i = 1; i < story.length; i++) {
        result.push({
            text: story[i]["paragraph_"+i],
            desc: descriptions[i]["paragraph_"+i]
        })
    }

    return result
}

const callEndpoint = async (url, prompt) => {
    try {
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
            },
                body: JSON.stringify({
                    prompt: prompt
                }),
            }
        )

        const result = await response.json();
        
        if (result.type === "text") {
            const parsedPayload = JSON.parse(JSON.parse(result.payload))
            
            return {
                type: "success",
                payload: prepareStory(parsedPayload)
            }

        }

        return {
            type: "success",
            payload: JSON.parse(result.payload)
        }
        
    } catch (error) {
        return {
            type: "error",
            payload: error
        }
    }
}