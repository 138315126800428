import "./Layout.css";
import Page from "./Page";

export default function Layout ({page, animationClass = "", editMode = false, getPageImage = () => {}, imageLoaders = [], textEditRef = null, descEditRef = null, onGenerateNewImage = () => {}, onRevertImage = () => {}}) {
    const isCover = page.index === 0 

    return (
        <div className={`screen ${animationClass}`} style={{opacity: `${page.index === -1 ? "0" : ""}`}}>
            <div className="content">
                <div className={"layout"}>
                    <div className="page">
                        <Page
                            pageType={isCover ? "back" : ""}
                            page={page}
                            editMode={editMode}
                            imageLoaders={imageLoaders}
                            textEditRef={textEditRef}
                        />
                    </div>
                    <div className={`page ${isCover ? "cover" : ""}`}>
                        <Page
                            pageType={isCover ? "cover" : "illustration"}
                            page={page}
                            editMode={editMode}
                            imageLoaders={imageLoaders}
                            getPageImage={getPageImage}
                            textEditRef={textEditRef}
                            descEditRef={descEditRef}
                            onGenerateNewImage={onGenerateNewImage}
                            onRevertImage={onRevertImage}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}