import "./Loading.css"

export default function Loading ({lineType = false}) {
    return (
        <div className="loading">
            {!lineType &&
                <div className="loading-gfx">
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="52" y="12" width="40" height="40" rx="6" transform="rotate(90 52 12)" stroke="black" strokeWidth="6"/>
                        <circle cx="32" cy="32" r="22" stroke="black" strokeWidth="6"/>
                        <path d="M54.5167 43C56.0563 45.6667 54.1318 49 51.0526 49L12.9474 49C9.86824 49 7.94374 45.6667 9.48334 43L28.5359 9.99998C30.0755 7.33332 33.9245 7.33332 35.4641 9.99999L54.5167 43Z" stroke="black" strokeWidth="6"/>
                    </svg>
                </div>
            }
            {lineType &&
                <div className="loading-line" style={{maskImage: `url(./img/rect.svg)`}}>
                    <div className="loading-line-gfx"  style={{backgroundImage: `url(./img/loading2-gfx.svg)`}}></div>
                </div>
            }
            
        </div>
    )
}