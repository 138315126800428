import Layout from "./Layout"
import TopBar from "./TopBar"
import "./Book.css"
import { useState, useEffect, useRef } from "react"

const BLANK_PAGE = {
    text: "",
    desc: "",
    index: -1,
}

export default function Book ({ openBook, setOpenBook, setShouldCloseBook, shouldCloseBook, story, getPageImage, generateImage, setPageImage, imageLoaders, printBook, loading }) {
    const [editMode, setEditMode] = useState(false)
    const [currIndex, setCurrIndex] = useState(0)
    const [currPage, setCurrPage] = useState(BLANK_PAGE)
    const [prevPage, setPrevPage] = useState(BLANK_PAGE)
    const [pageForward, setPageForward] = useState(true)
    const [book, setBook] = useState(story)
    const textEditRef = useRef()
    const descEditRef = useRef()
    
    const [prevImageDesc, setPrevImageDesc] = useState(null)

    useEffect(() => {
        setCurrPage({index:currIndex, ...book[currIndex]})
    }, [currIndex, book])

    const goNextPage = () => {
        setPageForward(true)

        if (openBook) {
            setPrevPage(currPage)
            setCurrIndex(Math.min(currIndex + 1, book.length - 1))
        } else {
            setOpenBook(true)
        }      
    }

    const goPrevPage = () => {
        setPageForward(false)

        if (currIndex > 0) {
            setPrevPage(currPage)
        } else {
            setPrevPage(BLANK_PAGE)
            setShouldCloseBook(true)
        }  
        
        setCurrIndex(Math.max(currIndex - 1, 0))
    }

    const editBook = (text, type) => {
        if (text === book[currIndex].text) return
        
        let newBook = [...book]
        newBook[currIndex][type] = text

        setBook(newBook)
    }

    const onSaveChanges = () => {
        editBook(textEditRef.current.getTextChanges(), "text")
        setEditMode(false)
    }

    const onGenerateNewImage = () => {
        const desc = descEditRef.current.getTextChanges()
        setPrevImageDesc({
            image: getPageImage(currIndex),
            desc: book[currIndex].desc
        })
        editBook(desc, "desc")
        generateImage(desc, currIndex)
    }

    const onRevertImage = () => {
        if (prevImageDesc === null) return

        editBook(prevImageDesc.desc, "desc")
        setPageImage(prevImageDesc.image, currIndex)
        setPrevImageDesc(null)
    }


    return (
        <div className="screen">
            {!editMode && (openBook && !shouldCloseBook) &&
                <button className="button large-icon icon-btn nav-btn left"
                    onClick={goPrevPage}
                >
                    <span className="icon print" style={{maskImage: "url(./img/icon-arrow.svg)"}}></span>
                </button>
            }
            {!editMode && (openBook && !shouldCloseBook) && (currIndex < book.length - 1) &&
                <button className="button large-icon icon-btn nav-btn right"
                    onClick={goNextPage}
                >
                    <span className="icon print" style={{maskImage: "url(./img/icon-arrow.svg)"}}></span>
                </button>
            }
            <TopBar 
                editMode={editMode}
                setEditMode={setEditMode}
                onSaveChanges={onSaveChanges}
                printBook={printBook}
                loading={loading}
            />
            <Layout
                key={prevPage.index * -1}
                page={prevPage}
                animationClass={pageForward ? "" : "PageBack"}
                imageLoaders={imageLoaders}
                getPageImage={getPageImage}
            />
            <Layout
                key={shouldCloseBook ? Math.random() : currPage.index}
                page={currPage}
                animationClass={pageForward ? "PageForward" : (shouldCloseBook ? "PageBack" : "")}
                imageLoaders={imageLoaders}
                getPageImage={getPageImage}
                editMode={editMode}
                textEditRef={textEditRef}
                descEditRef={descEditRef}
                onGenerateNewImage={onGenerateNewImage}
                onRevertImage={onRevertImage}
            />   
        </div>
    )
}