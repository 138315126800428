import "./Page.css"
import Cover from "./Cover";
import TextPage from "./TextPage";
import Illustration from "./Illustration";
import BackCover from "./BackCover";

export default function Page ({pageType, page, editMode, getPageImage, imageLoaders, textEditRef, descEditRef, onGenerateNewImage, onRevertImage}) {

    if (pageType === "cover") {
        return (
            <Cover
                page={page}
                editMode={editMode}
                getPageImage={getPageImage}
                imageLoaders={imageLoaders}
                textEditRef={textEditRef}
                descEditRef={descEditRef}
                onGenerateNewImage={onGenerateNewImage}
                onRevertImage={onRevertImage}
            />
        )
    }

    else if (pageType === "back") {
        return (
            <BackCover  />
        )
    }

    else if (pageType === "illustration") {
        return (
            <Illustration
                page={page}
                editMode={editMode}
                getPageImage={getPageImage}
                imageLoaders={imageLoaders}
                descEditRef={descEditRef}
                onGenerateNewImage={onGenerateNewImage}
                onRevertImage={onRevertImage}
            />
        )
    } 

    return (
        <TextPage
            page={page}
            editMode={editMode}
            textEditRef={textEditRef}  
        />
    )
}