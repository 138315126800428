import "./TextPage.css"
import EditBlock from "./EditBlock";

export default function TextPage({page, editMode, textEditRef}){
    const { text } = page 
    return (
        <div className="page">
            {!editMode &&
                <div className="text">
                    {text}
                </div>
            }
            {editMode && 
                <EditBlock
                    text={text}
                    editRef={textEditRef}
                />
            }
        </div>
    )
}