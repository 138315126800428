import "./TopBar.css"
import Loading from "./Loading"

export default function TopBar({editMode, setEditMode, onSaveChanges, printBook, loading}) {
    return (
        <div className="top-bar">
            {editMode &&
                <div className="edit-bar">
                    <div className="left-pad"></div>
                    <div className="edit-instruction">
                        Edit text & generate new image
                    </div>
                    <div className="right-buttons">
                        <button className="button simple-btn"
                            onClick={onSaveChanges}
                        >
                            Done
                        </button>
                    </div>
                </div>
            }
            
            {!editMode &&
                <div className={`right-buttons  ${loading ? "is-loading" : ""}`}>
                    <button className="button icon-btn med-icon"
                        onClick={() => setEditMode(true)}
                        disabled={loading}
                    >
                        <span className="icon edit" style={{maskImage: "url(./img/icon-pencil.svg)"}}></span>
                    </button>
                    <button className="button icon-btn med-icon print"
                        onClick={printBook}
                        disabled={loading}
                    >
                        <span className="icon print" style={{maskImage: "url(./img/icon-print.svg)"}}>
                        </span>
                        {loading && <Loading />}
                    </button>
                </div>
            }
        </div>
    )
}