import { useState, useEffect } from "react";
import "./PrintBook.css"
import Layout from "./Layout";

export default function PrintBook ({story, images, setPrintMode }) {
    let prevInit = 0
    useEffect(() => {
        
        if (prevInit === 0) {
            
            setTimeout(() => {
                window.print()
                setPrintMode(false)
            }, 10)
        }
        
        prevInit = 1
        
    }, [])

    const getPageImage = index => { 
        return "data:image/png;base64," + images[index]
    }

    return (
        <div className="print-wrapper">
            {story && story.map(
                (p, i) => (
                    <Layout 
                        key={i}
                        page={{...p, index: i}}
                        getPageImage={getPageImage}
                    />
                )
            )}
        </div>
    )  
}