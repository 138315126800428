import EditBlock from "./EditBlock"
import "./Illustration.css"
import Image from "./Image"
import { useState, useEffect } from "react"
import Loading from "./Loading"

export default function Illustration({page, editMode, getPageImage, imageLoaders, descEditRef, onGenerateNewImage, onRevertImage}) {
    const [loading, setLoading] = useState(undefined)

    useEffect(() => {
        setLoading(imageLoaders[page.index])
    }, [imageLoaders])

    return (
        <div className={`illustration ${loading === true ? "is-loading" : ""}`}>
            <Image 
                index={page.index}
                getPageImage={getPageImage}
                loading={loading}
            />

            {loading === true && <Loading />}
              
            {editMode &&
                <div className="image-edit">
                    <EditBlock
                        text={page.desc}
                        showButtons={true}
                        editRef={descEditRef}
                        onSubmit={onGenerateNewImage}
                        onUndo={onRevertImage}
                    />
                </div>
            }
            
        </div>
    )
}