import "./PostIt.css"

export default function PostIt() {
    return (
        <div className="postit-screen" >
            <div className="postit">
                <div className="close-postit">

                </div>
                <div className="postit-text">
                    <p>The A.I. agent is sleeping.</p>
                    <p>Contact du.hoang@gmail.com to turn wake them up.</p>
                </div>
            </div>
        </div>
    )
}