import "./IntroScreen.css"
import Loading from "./Loading"
import { useState, useEffect } from "react"

export default function IntroScreen ({loading, onCreateBook, apiLive}) {
    const [summary, setSummary] = useState("")
    const [notReady, setNotReady] = useState(false)
    
    useEffect(() => {
        if (apiLive ) {
            setNotReady(false)
        }
    }, [apiLive])

    const onClick = () => {
        if (apiLive) {
            onCreateBook(summary)
        } else {
            setNotReady(true)
        }
    }

    return (
        <div className="screen">
            <div className="content">
                <div className="intro-book">
                    <div className="intro-page left-page">
                        <div className="intro">
                            <div className="logo">Create <span>a</span> Story</div>
                            <div className="instruction">
                                <p>Create your own Children's Illustration Book with one click!</p>

                                <p>Write a summary of the story and your friendly A.I. agent will generate a picture book for you to print out.</p>
                            </div>
                        </div>
                    </div>
                    <div className="intro-page right-page">
                        <div className="page-content">
                            {loading && 
                                <Loading lineType={true}/>
                            }
                            <label>Story Summary</label>
                            <textarea placeholder="Ex. Chuck is a chicken on a farm, who likes to go on adventures with his friends..."
                                disabled={loading}
                                value={summary}
                                onChange={(e => setSummary(e.target.value))}
                            >
                            </textarea>
                            <div className="button-holder">
                                <button className={`button primary-btn large-btn ${notReady ? "not-ready" : ""}`}
                                    disabled={loading}
                                    onClick={onClick}
                                >
                                    <span>
                                        <span className="go label">Go</span>
                                        <span className="no-go label">
                                            Not Ready<span>.</span><span>.</span><span>.</span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}